import styled, { css } from 'styled-components';

import { TextInput as BaseTextInput } from 'components/UI/Input';
import {
	DESKTOP_GRID_COLUMN,
	DESKTOP_GRID_SIZE,
	DESKTOP_GRID_SPACING,
	TABLET_GRID_SIZE
} from 'consts';
import { Colors, MediaQueries } from 'environment';

export const Container = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	background-color: ${Colors.gray[1]};
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding: 12rem 0;

	@media ${MediaQueries.phone} {
		padding: 6rem 2rem;
	}

	@media ${MediaQueries.tablet} {
		max-width: ${TABLET_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} {
		max-width: ${DESKTOP_GRID_SIZE}rem;
	}
`;

export const Title = styled.h1`
	margin-bottom: 2rem;

	@media ${MediaQueries.phone} {
		font-family: 'Open Sans';
		font-size: 3.6rem;
		font-weight: 600;
		line-height: 5rem;
	}
`;

export const Description = styled.p`
	margin-bottom: 5rem;
	text-align: center;

	@media ${MediaQueries.phone} {
		margin-bottom: 3rem;
	}

	@media ${MediaQueries.desktop} {
		max-width: ${DESKTOP_GRID_COLUMN * 6 + DESKTOP_GRID_SPACING * 5}rem;
	}
`;

interface RowProps {
	columns?: number;
	center?: boolean;
}

export const Row = styled.div<RowProps>`
	display: flex;
	justify-content: ${({ center }) => center && 'center'};
	align-items: center;
	width: 100%;
	margin: 1rem 0;

	${({ columns }) =>
		columns &&
		css`
			max-width: ${DESKTOP_GRID_COLUMN * columns + DESKTOP_GRID_SPACING * (columns - 1)}rem;
		`}
`;

export const TextInput = styled(BaseTextInput)`
	max-width: ${DESKTOP_GRID_COLUMN * 3 + DESKTOP_GRID_SPACING * 2}rem;
	margin: 0 ${DESKTOP_GRID_SPACING}rem;

	@media ${MediaQueries.phone} {
		width: 9rem;
	}
`;

export const Label = styled.p`
	margin-left: 4rem;
`;

export const OverviewTitle = styled.h4`
	margin-top: 3rem;
	text-align: center;
`;

export const Price = styled.h2`
	margin-top: 2rem;

	@media ${MediaQueries.phone} {
		font-family: 'Open Sans';
		font-size: 3.6rem;
		font-weight: 600;
		line-height: 5rem;
	}
`;

export const OverviewDescription = styled.span`
	margin-top: 1rem;
	margin-bottom: 4rem;
	max-width: ${DESKTOP_GRID_COLUMN * 4 + DESKTOP_GRID_SPACING * 3}rem;
	text-align: center;
`;

import styled from 'styled-components';

import { Banner as BaseBanner } from 'components/UI/Banner';
import {
	DESKTOP_GRID_COLUMN,
	DESKTOP_GRID_SIZE,
	DESKTOP_GRID_SPACING,
	TABLET_GRID_SIZE
} from 'consts';
import { MediaQueries } from 'environment';

export const Container = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	margin-top: 4rem;
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;

	@media ${MediaQueries.tablet} {
		max-width: ${TABLET_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} {
		max-width: ${DESKTOP_GRID_SIZE}rem;
	}
`;

export const Banner = styled(BaseBanner)`
	@media ${MediaQueries.phone} {
		height: 58rem;

		& > div {
			top: 50%;
		}
	}

	@media ${MediaQueries.tablet} {
		height: 72rem;

		& > div {
			top: 40%;
		}
	}
`;

export const Title = styled.h1`
	margin-bottom: 2rem;
	text-align: center;

	@media ${MediaQueries.phone} {
		font-family: 'Open Sans';
		font-size: 3.6rem;
		font-weight: 600;
		line-height: 5rem;
	}
`;

export const Description = styled.p`
	margin-bottom: 12rem;
	text-align: center;

	@media ${MediaQueries.phone} {
		max-width: calc(100% - 4rem);
		margin-bottom: 6rem;
	}

	@media ${MediaQueries.desktop} {
		max-width: ${6 * DESKTOP_GRID_COLUMN + 5 * DESKTOP_GRID_SPACING}rem;
	}
`;

export const Plans = styled.div`
	display: flex;
	width: 100%;
	flex-direction: column;

	@media ${MediaQueries.desktop} {
		flex-direction: row;
	}
`;

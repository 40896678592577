import React, { useState, useEffect } from 'react';

import { Button, RichText, Switch } from 'components/UI';
import content from 'data/pricing.json';
import analytics from 'data/analytics.json';
import { MediaQueries } from 'environment';
import { useMediaQuery } from 'hooks';

import {
	Container,
	Content,
	Description,
	Label,
	OverviewTitle,
	OverviewDescription,
	Price,
	Row,
	TextInput,
	Title
} from './PricingCalculator.style';

interface Device {
	title: string;
	price: number;
	range: number;
}

interface SelectedDevices {
	[key: number]: Device;
}

function computePrice(devices: SelectedDevices, surface: number) {
	if (Number.isNaN(surface) || !surface) {
		return 0;
	}

	const values: Device[] = Object.values(devices);
	const price = values.reduce((acc, value) => {
		const numberOfRequiredDevices = surface / value.range;
		return acc + numberOfRequiredDevices * value.price;
	}, 0);

	return Math.max(Math.round(price), 1);
}

export function PricingCalculator() {
	const isMobile = useMediaQuery(MediaQueries.phone);
	const [selectedDevices, setSelectedDevices] = useState<SelectedDevices>({});
	const [surface, setSurface] = useState(content.calculator.surface.validation.default);
	const [price, setPrice] = useState(computePrice(selectedDevices, Number(surface)));

	useEffect(() => {
		setPrice(computePrice(selectedDevices, Number(surface)));
	}, [selectedDevices, surface]);

	function handleSurfaceChange(e: React.ChangeEvent<HTMLInputElement>) {
		if (Number.isNaN(Number(e.target.value))) {
			setSurface(content.calculator.surface.validation.default);
		}

		if (Number(e.target.value) <= content.calculator.surface.validation.max) {
			setSurface(Number(e.target.value));
		}
	}

	function handleBlur(e: React.FocusEvent<HTMLInputElement>) {
		if (!e.target.value) {
			setSurface(content.calculator.surface.validation.default);
		}
	}

	function handleSwitchChange(value: boolean, index: number) {
		setSelectedDevices(devices => {
			const copy = { ...devices };

			if (value) {
				copy[index] = content.calculator.features[index];
			} else {
				delete copy[index];
			}

			return copy;
		});
	}

	return (
		<Container>
			<Content>
				<Title>{content.calculator.title}</Title>
				<RichText>
					<Description>{content.calculator.description}</Description>
				</RichText>
				<Row center>
					<RichText>
						<p>{content.calculator.surface.label}</p>
					</RichText>
					<TextInput
						max={content.calculator.surface.validation.max}
						min={content.calculator.surface.validation.min}
						type="number"
						value={surface.toString()}
						onBlur={handleBlur}
						onChange={handleSurfaceChange}
					/>
					<RichText>
						<p>{content.calculator.surface.unit}</p>
					</RichText>
				</Row>
				{content.calculator.features.map((feature, i) => (
					<Row key={`pricing-feature-${i}`} columns={4}>
						<Switch
							id={`pricing-switch-${i}`}
							on
							onChange={value => handleSwitchChange(value, i)}
						/>
						<Label>{feature.title}</Label>
					</Row>
				))}
				<OverviewTitle>{content.calculator.overview.title}</OverviewTitle>
				<Price>
					{price} {content.calculator.overview.currency}{' '}
					{content.calculator.overview.interval}
				</Price>
				<OverviewDescription>{content.calculator.overview.description}</OverviewDescription>
				<Button
					name={analytics.pricingCalculatorContactSales}
					to="/request-quote"
					state={{ plan: 'Custom' }}
					width={isMobile ? '100%' : 38}
				>
					{content.calculator.cta}
				</Button>
			</Content>
		</Container>
	);
}

import styled from 'styled-components';

import { DESKTOP_GRID_SIZE, TABLET_GRID_SIZE } from 'consts';
import { Colors, MediaQueries, Sizes } from 'environment';

export const Container = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	background-color: ${Colors.indigo};
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding: 12rem 0;

	@media ${MediaQueries.phone} {
		padding: 6rem 2rem;
	}

	@media ${MediaQueries.tablet} {
		max-width: ${TABLET_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} {
		max-width: ${DESKTOP_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} and (max-width: ${Sizes.xl}) {
		padding-left: 2rem;
		padding-right: 2rem;
	}
`;

export const Title = styled.h2`
	color: ${Colors.gray[1]};
	margin-bottom: 8rem;
	text-align: center;

	@media ${MediaQueries.phone} {
		font-size: 2.4rem;
		line-height: 2.9rem;
		margin-bottom: 4rem;
	}
`;

import React from 'react';

import {
	PricingCalculator,
	PricingContact,
	PricingFAQ,
	PricingFeatures,
	PricingPlans
} from 'components/Pricing';
import { SEO } from 'components/UI';
import seo from 'data/seo.json';

export default function Pricing() {
	return (
		<>
			<SEO
				title={seo.pricingTitle}
				description={seo.pricingDescription}
				ogTitle={seo.pricingOgTitle}
				ogDescription={seo.pricingOgDescription}
				ogImage={seo.pricingOgImage}
			/>
			<PricingPlans />
			<PricingFeatures />
			<PricingCalculator />
			<PricingFAQ />
			<PricingContact />
		</>
	);
}

import React from 'react';

import content from 'data/pricing.json';
import { useQueryContext } from 'hooks';

import { PlanCard } from '../PlanCard';
import { Banner, Container, Content, Description, Plans, Title } from './PricingPlans.style';

export function PricingPlans() {
	const { images } = useQueryContext();

	return (
		<Container>
			{images.banners.pricing && <Banner image={images.banners.pricing} />}
			<Content>
				<Title>{content.title}</Title>
				<Description>{content.description}</Description>
				<Plans>
					{content.plans.map(plan => (
						<PlanCard key={`pricing-${plan.type}-plan`} {...plan} />
					))}
				</Plans>
			</Content>
		</Container>
	);
}

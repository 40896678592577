import React from 'react';

import content from 'data/pricing.json';

import { FrequentlyAskedQuestion } from '../FrequentlyAskedQuestion';
import { Container, Content, Title } from './PricingFAQ.style';

export function PricingFAQ() {
	return (
		<Container id="faqs">
			<Content>
				<Title>{content.faq.title}</Title>
				{content.faq.items.map((item, index) => (
					<FrequentlyAskedQuestion key={`faq-${index}`} {...item} />
				))}
			</Content>
		</Container>
	);
}

import React from 'react';

import { Button } from 'components/UI';
import content from 'data/pricing.json';
import analytics from 'data/analytics.json';
import { MediaQueries } from 'environment';
import { useMediaQuery } from 'hooks';

import { Container, Content, Title } from './PricingContact.style';

export function PricingContact() {
	const isPhone = useMediaQuery(MediaQueries.phone);

	return (
		<Container>
			<Content>
				<Title>{content.contact.title}</Title>
				<Button
					name={analytics.pricingFooterContactUs}
					width={isPhone ? '100%' : 38}
					to="/contact/"
				>
					{content.contact.cta}
				</Button>
			</Content>
		</Container>
	);
}

import { animated } from 'react-spring';
import styled from 'styled-components';

import { Colors, MediaQueries } from 'environment';

export const AnimatedContainer = animated.div;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	height: fit-content;
	align-items: center;
	background-color: ${Colors.gray[1]};
	border-radius: 0.4rem;
	position: relative;
	padding: 8rem 4rem;

	&:nth-child(2) {
		background-color: ${Colors.indigo};
		top: 0;
		box-shadow: 0 20px 40px 0 rgba(36, 55, 70, 0.2);
	}

	@media ${MediaQueries.phone} {
		border-radius: 0;
		padding: 4rem 2rem;

		&:nth-child(2) {
			padding: 4rem 2rem;
		}
	}

	@media ${MediaQueries.desktop} {
		width: 38rem;
		top: 2rem;

		&:nth-child(2) {
			width: 42rem;
			padding: 10rem 6rem;
		}
	}
`;

export const PlanType = styled.h4`
	text-align: center;
	text-transform: uppercase;
	margin-bottom: 2rem;

	${Container}:nth-child(2) & {
		color: ${Colors.gray[5]};
	}
`;

export const Price = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;
`;

export const CurrencyAndInterval = styled.h3`
	font-family: 'Open Sans';
	font-weight: 600;

	${Container}:nth-child(2) & {
		color: ${Colors.white};
	}
`;

export const Amount = styled.h1`
	margin: 0 1rem;

	${Container}:nth-child(2) & {
		color: ${Colors.white};
	}
`;

export const PriceDetails = styled.span`
	color: ${Colors.indigo};
	text-align: center;
	margin-top: 1rem;
	margin-bottom: 2rem;

	${Container}:nth-child(2) & {
		color: ${Colors.gray[4]};
	}
`;

export const CustomPrice = styled.p`
	color: ${Colors.green};
	text-align: center;
	text-transform: uppercase;
	margin-top: 2rem;
	margin-bottom: 6.4rem;
`;

export const Features = styled.div`
	width: 100%;
	margin-top: 2rem;
`;

export const FeatureList = styled.ul`
	margin-top: 2rem;
	list-style-position: inside;
`;

export const FeatureItem = styled.li`
	text-align: center;

	@media ${MediaQueries.desktop} {
		text-align: start;
	}

	// prettier-ignore
	${Container}:nth-child(2) & {
		color: ${Colors.gray[4]};
	}
`;

export const ToggleKitContainer = styled.div`
	display: flex;
	width: 100%;
	justify-content: center;

	@media ${MediaQueries.desktop} {
		justify-content: flex-start;
	}
`;

export const ToggleKit = styled.p`
	position: relative;
	cursor: pointer;
	color: ${Colors.green};
	font-weight: bold;
	margin-top: 2rem;
	width: fit-content;

	@media ${MediaQueries.desktop} {
		border-bottom: 0.2rem ${Colors.gray[1]} solid;
		transition: border-color 0.2s;
		height: 3rem;

		// prettier-ignore
		${Container}:nth-child(2) & {
			border-color: ${Colors.indigo};

			&:hover {
				border-color: ${Colors.green};
			}
		}

		&:hover {
			border-color: ${Colors.green};
		}
	}
`;

import React from 'react';

import { IconFeature } from 'components/UI';
import content from 'data/pricing.json';

import { Container, Content, Features, Link, Title } from './PricingFeatures.style';

export function PricingFeatures() {
	return (
		<Container>
			<Content>
				<Title>{content.features.title}</Title>
				<Features>
					{content.features.items.map((feature, i) => (
						<IconFeature key={`pricing-feature-${i}`} {...feature} />
					))}
				</Features>
				<Link to="/solution/">{content.features.cta}</Link>
			</Content>
		</Container>
	);
}

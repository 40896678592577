import { animated } from 'react-spring';
import styled from 'styled-components';
import { Colors, MediaQueries } from 'environment';

export const AnimatedContainer = animated.div;

export const Container = styled.div`
	display: flex;
	justify-content: space-between;
	width: 100%;
	padding: 4rem 0;

	&:not(:last-child) {
		border-bottom: 0.1rem ${Colors.gray[5]} solid;
	}

	@media ${MediaQueries.phone} {
		padding: 2rem 0;
	}
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	flex: 1;
`;

export const Row = styled.div`
	display: flex;
	width: 100%;
`;

export const IconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 4rem;
	height: 4rem;
	margin-right: 6rem;
	margin-top: 0.5rem;

	@media ${MediaQueries.phone} {
		margin-right: 2rem;
		margin-top: 0;
	}
`;

export const Spacer = styled.div`
	width: 4rem;
	min-width: 4rem;
	margin-right: 6rem;

	@media ${MediaQueries.phone} {
		margin-right: 2rem;
	}
`;

export const Question = styled.h3`
	cursor: pointer;
	z-index: 1;

	@media ${MediaQueries.phone} {
		font-size: 2.4rem;
		line-height: 2.9rem;
	}

	@media ${MediaQueries.tablet} {
		font-family: 'Open Sans';
		font-weight: 600;
	}

	@media ${MediaQueries.desktop} {
		font-family: 'Open Sans';
		font-weight: 600;
	}
`;

export const Answer = styled.p`
	margin-top: 2rem;
	z-index: -1;
`;

import styled from 'styled-components';

import { TextLink } from 'components/UI/TextLink';
import { DESKTOP_GRID_SIZE, TABLET_GRID_SIZE } from 'consts';
import { MediaQueries } from 'environment';

export const Container = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;

	@media ${MediaQueries.desktop} {
		margin-top: 4rem;
	}
`;

export const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding: 12rem 0;

	@media ${MediaQueries.phone} {
		padding: 6rem 2rem;
	}

	@media ${MediaQueries.tablet} {
		max-width: ${TABLET_GRID_SIZE}rem;
	}

	@media ${MediaQueries.desktop} {
		max-width: ${DESKTOP_GRID_SIZE}rem;
	}
`;

export const Title = styled.h2`
	margin-bottom: 6rem;
	text-align: center;

	@media ${MediaQueries.phone} {
		font-size: 2.4rem;
		line-height: 2.9rem;
		margin-bottom: 4rem;
	}

	@media ${MediaQueries.tablet} {
		margin-bottom: 8rem;
	}
`;

export const Features = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;

	@media ${MediaQueries.desktop} {
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
	}
`;

export const Link = styled(TextLink)`
	text-transform: uppercase;

	@media ${MediaQueries.desktop} {
		margin-top: 8rem;
	}
`;

import React, { useState } from 'react';

import { ExpandableIcon, RichText } from 'components/UI';
import { useMeasure, useSpring } from 'hooks';

import {
	AnimatedContainer,
	Answer,
	Container,
	Content,
	IconContainer,
	Question,
	Row,
	Spacer
} from './FrequentlyAskedQuestion.style';

interface Props {
	question: string;
	answer: string;
}

export function FrequentlyAskedQuestion({ question, answer }: Props) {
	const [ref, { height }] = useMeasure<HTMLParagraphElement>();
	const [expanded, setExpanded] = useState(false);

	function handleToggle() {
		setExpanded(state => !state);
	}

	const animation = useSpring({
		from: { height: 0, opacity: 0 },
		to: {
			height: expanded ? height + 20 : 0,
			opacity: expanded ? 1 : 0
		}
	});

	return (
		<Container>
			<Content>
				<Row>
					<IconContainer>
						<ExpandableIcon expanded={expanded} onClick={handleToggle} />
					</IconContainer>
					<Question onClick={handleToggle}>{question}</Question>
				</Row>
				<Row>
					<Spacer />
					<AnimatedContainer style={animation}>
						<RichText>
							<Answer ref={ref}>{answer}</Answer>
						</RichText>
					</AnimatedContainer>
				</Row>
			</Content>
		</Container>
	);
}

import React, { useState } from 'react';

import { Button, RichText } from 'components/UI';
import analytics from 'data/analytics.json';
import { MediaQueries } from 'environment';
import { useMeasure, useMediaQuery, useSpring } from 'hooks';

import {
	Amount,
	AnimatedContainer,
	Container,
	CurrencyAndInterval,
	CustomPrice,
	Features,
	FeatureList,
	FeatureItem,
	PlanType,
	Price,
	PriceDetails,
	ToggleKitContainer,
	ToggleKit
} from './PlanCard.style';

interface Price {
	currency?: string;
	amount?: string;
	interval?: string;
	details?: string;
	custom?: string;
}

interface Props {
	type: string;
	price: Price;
	cta: string;
	features: string[];
	hardware?: {
		expand: string;
		collapse: string;
		kit: string[];
	};
}

export function PlanCard({ type, price, cta, features, hardware }: Props) {
	const [listRef, { height: listHeight }] = useMeasure<HTMLUListElement>();
	const [expanded, setExpanded] = useState(false);

	const isTablet = useMediaQuery(MediaQueries.tablet);

	const listAnimation = useSpring({
		from: { height: 0, opacity: 0 },
		to: { height: expanded ? listHeight : 0, opacity: expanded ? 1 : 0 }
	});

	return (
		<Container>
			<PlanType>{type}</PlanType>
			{price.currency && price.amount && price.interval && (
				<>
					<Price>
						<CurrencyAndInterval>{price.currency}</CurrencyAndInterval>
						<Amount>{price.amount}</Amount>
						<CurrencyAndInterval>{price.interval}</CurrencyAndInterval>
					</Price>
					{price.details && <PriceDetails>{price.details}</PriceDetails>}
				</>
			)}
			{price.custom && <CustomPrice>{price.custom}</CustomPrice>}
			<Button
				name={analytics.pricingCardContactSales}
				to="/request-quote/"
				state={{ plan: type }}
				outline={!!price.custom}
				width={isTablet ? 30 : '100%'}
			>
				{cta}
			</Button>
			<Features>
				<FeatureList>
					{features.map((feature, i) => (
						<RichText key={`${type}-plan-feature-${i}`}>
							<FeatureItem>{feature}</FeatureItem>
						</RichText>
					))}
				</FeatureList>
				{hardware && (
					<>
						<AnimatedContainer style={listAnimation}>
							<FeatureList ref={listRef}>
								{hardware.kit.map((feature, i) => (
									<RichText key={`${type}-plan-hardware-feature-${i}`}>
										<FeatureItem>{feature}</FeatureItem>
									</RichText>
								))}
							</FeatureList>
						</AnimatedContainer>
						<ToggleKitContainer>
							<ToggleKit onClick={() => setExpanded(state => !state)}>
								{expanded ? hardware.collapse : hardware.expand}
							</ToggleKit>
						</ToggleKitContainer>
					</>
				)}
			</Features>
		</Container>
	);
}
